const orange = '#FF8A14';
const beige = '#FEEBDC';
const blue = '#008EE5';
const red = '#FF3937';
const pink = '#EFBFD3';
const green = '#0ACA7F';
const black = '#000000';
const white = '#ffffff';
const gray = '#333333';

type Color = { [key: string]: string };

export const color: Color = {
  orange,
  beige,
  blue,
  red,
  pink,
  green,
  black,
  white,
  gray,

  /**
   * Beige
   */
  dolceVita: beige,
  /**
   * Pink
   */
  oldRose: pink,
  /**
   * Red
   */
  rosso: red,
  /**
   * Green
   */
  verde: green,
  /**
   * Orange
   */
  esatto: orange,
  /**
   * Blue
   */
  ilGrandeBlu: blue,
};
