import { color } from './color';

export const theme = {
  fontSizes: [0, 14, 16, 20, 24, 32, 48, 64, 72, 84, 96, 128],
  textStyles: {
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '0.02em',
    },
  },
  breakpoints: ['40em', '52em', '64em', '80em', '96em'],
  colors: {
    primary: color.orange,
    text: '#000',
    text2: '#555',
    text3: '#292929',
    text4: '#828282',
    bg: '#fff',
    inverseBg: '#f0f0f0',
    line: 'rgba(0, 0, 0, 0.02)',
    tease: '#eee',
    teaseHover: color.orange,
    mapFill: '#202020',
    greenPrimary: '#53FC91',
    bluePrimary: '#00BAFF',
    error: 'red',
  },
};

export type ThemeType = typeof theme;

const darkTheme: ThemeType = {
  ...theme,
  colors: {
    ...theme.colors,
    bg: '#000',
    text: '#fff',
    text2: '#aaa',
    text3: '#fff',
    text4: '#828282',
    line: 'rgba(255, 255, 255, 0.05)',
    inverseBg: '#232222',
    tease: '#232222',
    mapFill: color.white,
  },
};

export const themes = {
  light: theme,
  dark: darkTheme,
};

export const grid = [
  '0px minmax(auto, 700px) 0px',
  '1fr minmax(auto, 800px) 1fr',
  '1fr minmax(auto, 900px) 1fr',
  '1fr minmax(auto, 1000px) 1fr',
  '1fr minmax(auto, 1200px) 1fr',
  '1fr minmax(auto, 1300px) 1fr',
];

export const headerGrid = [
  '100px auto',
  '1fr minmax(auto, 800px) 1fr',
  '1fr minmax(auto, 900px) 1fr',
  '1fr minmax(auto, 1000px) 1fr',
  '1fr minmax(auto, 1200px) 1fr',
  '1fr minmax(auto, 1300px) 1fr',
];

export const bodyPadding = [3, 2, 2, 0];
