import { createGlobalStyle } from 'styled-components';

import HandsomeRegularWoff from './Handsome-Regular.woff';
import HandsomeRegularWoff2 from './Handsome-Regular.woff2';
import Rubik from './Rubik.woff2';

export default createGlobalStyle`
    @font-face {
        font-family: 'Handsome';
        src: local('Handsome-Regular'), local('Handsome Regular'),
        url(${HandsomeRegularWoff2}) format('woff2'),
        url(${HandsomeRegularWoff}) format('woff');
    }

    @font-face {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url(${Rubik}) format('woff2');
    }

    @font-face {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${Rubik}) format('woff2');
    }

    @font-face {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url(${Rubik}) format('woff2');
    }

    @font-face {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(${Rubik}) format('woff2');
    }
`;
