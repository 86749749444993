import 'intersection-observer';
import React from 'react';
import { Helmet } from 'react-helmet';
import { GlobalContextProvider } from '../components/GlobalContext';
import { CurrentThemeProvider } from '../components/ThemeChanger';
import GlobalFonts from '../fonts/fonts';

const TemplateWrapper: React.FC<any> = ({ children, location, ...rest }) => {
  return (
    <CurrentThemeProvider>
      <GlobalContextProvider>
        <GlobalFonts />
        <Helmet>
          <link rel="preconnect" href="https://cloud.typography.com" />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cloud.typography.com/6764838/6682012/css/fonts.css"
            media="print"
            onLoad={"this.media='all'" as any}
          />

          <script>{`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${process.env.GATSBY_GTM_KEY}');
          `}</script>
        </Helmet>
        {children}
      </GlobalContextProvider>
    </CurrentThemeProvider>
  );
};

export default TemplateWrapper;
