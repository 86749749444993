import React, { FC, createContext, PropsWithChildren, useReducer } from 'react';

interface State {
  isModalOpen: boolean;
  isGDPRModalOpen: boolean;
}

interface Action {
  type: string;
  payload: any;
}

interface Context {
  context: State;
  dispatch: React.Dispatch<Action>;
}

const reducer = (state: State, { type, payload }: Action): State => {
  switch (type) {
    case 'isModalOpen':
      return { ...state, isModalOpen: payload };
    case 'isGDPRModalOpen':
      return { ...state, isGDPRModalOpen: payload };
  }

  return state;
};

const initialState = {
  isModalOpen: false,
  isGDPRModalOpen: false,
};

const initialContext: Context = {
  context: initialState,
  dispatch: () => null,
};

export const GlobalContext = createContext(initialContext);

export const GlobalContextProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <GlobalContext.Provider value={{ context: state, dispatch }}>{children}</GlobalContext.Provider>
  );
};
