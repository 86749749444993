import React, { createContext, SetStateAction, useContext, useMemo, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { themes } from '../theme/theme';

export type ThemeContextType = {
  current: 'dark' | 'light';
  switchCount: number;
  setTheme(setter: SetStateAction<ThemeContextType['current']>): void;
};

const ThemeContext = createContext<ThemeContextType>({
  current: 'light',
  switchCount: 0,
  setTheme: () => {},
});

ThemeContext.displayName = 'ThemeContext';

export const useCurrentThemeName = () => {
  return useContext(ThemeContext).current;
};

export const useCurrentTheme = () => {
  return themes[useContext(ThemeContext).current];
};

export const useSwitchCount = () => {
  return useContext(ThemeContext).switchCount;
};

export const ThemeChanger: React.FC = () => {
  const ctx = useContext(ThemeContext);

  return (
    <Wrapper>
      <StyledButton
        onClick={() => ctx.setTheme(current => (current === 'dark' ? 'light' : 'dark'))}
      >
        {textToTitle(ctx.current)}
      </StyledButton>
    </Wrapper>
  );
};

export const useThemeContext = () => {
  return useContext(ThemeContext);
}

const StyledButton = styled.button`
  color: ${props => props.theme.colors.text};
  border: none;
  background-color: ${props => props.theme.colors.inverseBg};
`;

const Wrapper = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 40em) {
    position: static;
  }
`;

export const CurrentThemeProvider: React.FC = ({ children }) => {
  // const [current, setTheme] = usePersistingState<ThemeContextType['current']>('theme', () => {
  const [current, setTheme] = useState<ThemeContextType['current']>(() => {
    // TODO: Disabled until fixed: https://github.com/styled-components/styled-components/issues/2880
    // const dark = getMediaQuery();
    // return dark ? 'dark' : 'light';
    return 'light';
  });
  const [switchCount, setSwitchCount] = useState(0);

  const value = useMemo(
    () => ({
      current,
      setTheme: (setter: SetStateAction<ThemeContextType['current']>) => {
        setTheme(setter);
        setSwitchCount(count => count + 1);
      },
      switchCount,
    }),
    [current, setTheme, setSwitchCount, switchCount]
  );

  const theme = useMemo(() => themes[current], [current]);

  return (
    <ThemeContext.Provider value={value}>
      <ThemeProvider theme={theme}>
        <>{children}</>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

CurrentThemeProvider.displayName = 'CurrentThemeProvider';

const textToTitle = (type: 'dark' | 'light' | null) => {
  switch (type) {
    case 'dark':
      return 'Night mode 🌙';
    case 'light':
      return 'Light mode ☀️';
    default:
      'nope';
  }
};

const getMediaQuery = () => {
  if (typeof window === 'undefined') return false;
  const darkQuery = '(prefers-color-scheme: dark)';
  const mql = window.matchMedia ? window.matchMedia(darkQuery) : null;
  if (!mql) return false;
  const dark = mql.media === darkQuery;
  return dark && mql.matches;
};
